import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppRoute } from '@app/shared/app.route.enum';
import { __ } from '@app/shared/functions/object.functions';
import { HttpCacheService } from '@app/core/http/http-cache.service';
import { RedirectService } from '@app/shared/services/redirect.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RouteService } from '../route.service';
import { AuthenticationService } from './authentication.service';
import { LicensesService } from '@app/shared/services/licenses.service';
import { I18nService } from '../i18n.service';

@Injectable()
export class AuthenticationGuard  {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private redirectService: RedirectService,
    private toastr: ToastrService,
    private i18nService: I18nService,
    private translateService: TranslateService,
    private httpCacheService: HttpCacheService
  ) { }


  // -----------------------------------------------------------------------------------------------------
  // @ INTERFACES
  // -----------------------------------------------------------------------------------------------------

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.authenticationService.isAuthenticated()) {

      // if (!this.authenticationService.hasAnyRole([Role.User, Role.Creator, Role.Curator, Role.Administrator, Role.CompanyAdministrator])) {
      //   return false;
      // }

      const data = RouteService.MergeData(route);

      if (!__.IsNullOrUndefined(data['roles'])) {
        const allowed = this.authenticationService.hasAnyRole(data['roles']);
        if (allowed) {
          return true;
        } else {
          return this.checkForbidden(route);
        }
      }

      return true;
    }

    this.toastr.error(this.translateService.instant('General.You are not logged in. Please login to continue to this website.'));
    this.authenticationService.logout();
    this.httpCacheService.clearCache(LicensesService.licenseGetUrl);

    this.redirectService.setRedirectUri(window.location.pathname);

    this.router.navigate([this.i18nService.getAbsoluteUrlWithCurrentLanguage(`/${AppRoute.Login}`)], { replaceUrl: true });
    return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    if (this.authenticationService.isAuthenticated()) {

      // Get route data from first child route
      const data = RouteService.MergeFirstChildData((route));

      if (!__.IsNullOrUndefined(data['roles'])) {
        const allowed = this.authenticationService.hasAnyRole(data['roles']);
        if (allowed) {
          return true;
        } else {
          return this.checkForbidden(route);
        }
      }

      return true;
    }

    this.toastr.error(this.translateService.instant('General.You are not logged in. Please login to continue to this website.'));
    this.authenticationService.logout();
    this.httpCacheService.clearCache(LicensesService.licenseGetUrl);

    this.redirectService.setRedirectUri(window.location.pathname);

    this.router.navigate([this.i18nService.getAbsoluteUrlWithCurrentLanguage(`/${AppRoute.Login}`)], { replaceUrl: true });
    return false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -----------------------------------------------------------------------------------------------------

  private checkForbidden(route: ActivatedRouteSnapshot): boolean {
    // TODO: POTENTIALLY BREAKING
    if ((route as any)['_routerState'].url === '/' || (route as any)['_routerState'].url.includes(AppRoute.Home)) {
      return true;
    }
    this.toastr.error(this.translateService.instant('General.You are not allowed to view this page. Please ask your administrator for permissions to view this page'));
    this.router.navigate([this.i18nService.getAbsoluteUrlWithCurrentLanguage(`/${AppRoute.Home}`)], { replaceUrl: true });

    return false;
  }
}
